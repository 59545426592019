.content-slide-item__title {
  @include font-size(3);
  color: $white;
  font-family: 'NoyhBold', Arial, sans-serif;
  opacity: 0;
  padding-bottom: $default-spacing * 2;
  transform: translateY(-10px) translateZ(0);
  transition: all 0.8s $ease-in-out-cubic;
}

.content-slide-item__description {
  line-height: 2rem;
  font-family: $font-secondary;
  font-kerning: none;
  opacity: 0;
  transform: translateY(-10px) translateZ(0);
  transition: all 0.8s $ease-in-out-cubic 0.2s;

  @media #{$tablet} {
    @include font-size(1.25);
    line-height: 2rem;
  }
}

.work-slider__placeholder {
  position: relative;
  width: 100%;

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
    }
    .content-slide-item {
      height: 100%;
    }

    img {
      max-height: 100%;
    }
  }

  .content-slide-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.custom-dots__placeholder {
  position: relative;
  width: 100%;
  // height: 20%;
  min-height: 60px;

  .custom-dots {
    padding: $default-spacing;
    margin-top: $default-spacing * 2;
  }
}

.content-slide-item {
  color: $white;
  transition: all 0.5s $ease-in-out-cubic 0.1s;

  img,
  iframe {
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.5s $ease-in-out-cubic;
  }

  &.active {
    img,
    iframe {
      transform: translateY(0);
      opacity: 1;
    }

    .content-slide-item__title {
      transform: translateY(0);
      opacity: 1;
    }

    .content-slide-item__description {
      transform: translateY(0);
      opacity: 1;
    }

    @media #{$tablet} {
      transform: translateY(0px);
    }
  }
}
