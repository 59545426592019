.page-contact {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.contact-error {
  @include font-size(1.25);
  color: $color-pink;
}

.contact-form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: auto;
  flex: 1;
}

.contact-actions {
  display: flex;
  justify-content: space-between;
}

.contact-form__success {
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  flex-direction: column;
  width: 100%;
  color: $white;
  flex: 1;
  justify-content: center;
  align-items: center;

  .page-title {
    opacity: 0;
    transform: translateX(0) translateY(0);
  }

  .page-description {
    text-align: center;
    padding: $default-spacing * 2 $default-spacing;

    p {
      margin: $default-spacing 0;
    }
  }
}

.contact-page__success-title {
  @include font-size(3.5);
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.8s $ease-in-out-cubic;

  @media #{$tablet} {
    @include font-size(5);
    line-height: 5.5rem;
  }

  .success & {
    transform: translateY(0);
    opacity: 1;
  }
}

.contact-page__success-description {
  @include font-size(1.25);
  text-align: center;
  line-height: 2rem;
  font-family: $font-secondary;
  transform: translateY($default-spacing * 3) translateZ(0);
  opacity: 0;
  transition: all 0.8s $ease-in-out-cubic 0.1s;

  p {
    padding-bottom: $default-spacing;
  }
  .success & {
    transform: translateY(0);
    opacity: 1;
  }
}

.contact-container {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
