.bump-container {
  width: 100%;

  .arrow {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.bump {
  height: 0;
  transition: all 0.5s $ease-out-back 0.5s;
  width: 100%;
  display: none;

  .loaded & {
    height: 50px;
  }

   @media #{$tablet} {
    position: absolute;
    bottom: 100%;
    display: block;
  }
}
