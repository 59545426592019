.page-practice {
  .background {
    transform: rotate(180deg);
  }
}

.work-slides {
  pointer-events: none;
  width: 100vw;

  &.compact {
    pointer-events: all;
  }

  @media #{$tablet} {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
  }
}

.work-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.work-intro__container {
  display: flex;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  .page-info,
  .logo-container {
    flex: 1;
  }

  @media #{$tablet} {
    flex-direction: row;

    .page-info {
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 1s $ease-out-cubic;

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: $white;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: $default-spacing * 4 $default-spacing * 2;

  @media #{$tablet} {
    padding: 0 $default-spacing * 4;
  }
}

.client-logo {
  display: flex;
  width: 50%;
  height: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: $default-spacing * 2;
  opacity: 0;

  img {
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 100px;
    display: inline-block;
  }
}
