.page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: all 0.3s $ease-out-cubic;
}

.page-content {
  position: relative;
}

.home-page-info {
  z-index: 10;
}

.page-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  color: $white;
  padding: $default-spacing * 2;
  transition: all 0.4s $ease-in-out-sine;

  p {
    padding-bottom: $default-spacing * 2;
  }

  @media #{$tablet} {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: $default-spacing * 10 $default-spacing * 4;
    transform: translateX(-50%) translateY(-50%);
  }
}

.page-title__container {
  position: relative;
  width: 100%;
  margin-bottom: $default-spacing * 2;
  // transition: all 0.8s $ease-in-out-cubic;
  @media #{$tablet} {
    .compact & {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}

.page-title {
  @include font-size(3.5);
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.8s $ease-in-out-cubic;

  @media #{$tablet} and (min-height: 769px) {
    @include font-size(5);
    line-height: 5.5rem;
  }

  .loaded & {
    transform: translateY(50%);
    opacity: 1;
  }
}

.page-title--bottom {
  transform: translateY(-100%);

  .loaded & {
    transform: translateY(-50%);
  }
}

.page-title__mask {
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden;
}

.page-title__mask--bottom {
  transform: translateY(0);
  top: 50%;
  position: absolute;
}

.page-description {
  line-height: 2rem;
  font-family: $font-secondary;
  transform: translateY(-$default-spacing * 3) translateZ(0);
  opacity: 0;

  @media #{$tablet} and (min-height: 769px) {
    @include font-size(1.25);
    line-height: 2rem;

    .compact & {
      opacity: 0 !important;
      transform: translateY(-20px) !important;
    }
  }
}
