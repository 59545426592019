.icon-scroll {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: $default-spacing;
  transition: all 0.3s;
}

.icon-scroll {
  display: none;
  width: 20px;
  height: 20px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;

  &.compact {
    opacity: 0;
  }

  @media #{$tablet} {
    display: block;
  }
}

.icon-scroll:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url('images/icons/arrow.svg') no-repeat 0 0;
  width: 20px;
  height: 20px;
  background-size: contain;
}

@keyframes scroll {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
