.column {
  flex: 1;
}

hr {
  border: 0;
  background: rgba(0, 0, 0, 0.15);
  height: 1px;
  margin: $default-spacing 0;
}
