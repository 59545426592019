.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
}

#fireflies {
  position: fixed;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  transform: scale(0.5);
  transform-origin: 0 0;
  z-index: 2;
  mix-blend-mode: overlay;
}

.color-filter {
  mix-blend-mode: color;
}

.color-multiply {
  mix-blend-mode: multiply;
}

.color-exclusion {
  mix-blend-mode: exclusion;
}

.color-soft-light {
  mix-blend-mode: soft-light;
}

.color-overlay {
  mix-blend-mode: overlay;
}

.color-saturation {
  mix-blend-mode: saturation;
}

.color-difference {
  mix-blend-mode: difference;
}
