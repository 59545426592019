.page-home {
  padding: 0;
  display: flex;
}

.home-intro {
  position: absolute;
  color: $white;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: $default-spacing * 2;
  display: flex;
  z-index: 2;

  path,
  #period circle {
    fill: $color-blue--home;
  }
  circle,
  line {
    stroke: $color-blue--home;
  }
}

.intro-logo,
.home-tagline {
  flex: 1;
  width: 100%;
  height: 100%;
}

.intro-logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.home-logo {
  padding: $default-spacing * 2;
  max-width: 320px;
  width: 100%;
  height: 100%;
}

.home-tagline {
  display: flex;
  align-items: center;
}

.home-tagline__content {
  border-left: 1px solid $color-blue--home;
  padding: 0 $default-spacing * 2;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s $ease-in-out-cubic;
}

.home-tagline__line {
  @include font-size(0.8675);
  font-family: $font-secondary;
  color: $color-blue--home;
  opacity: 0;

  @media #{$tablet} {
    @include font-size(2);
  }
}
