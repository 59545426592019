.words-page-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  color: $white;
  padding: $default-spacing * 2;
  transition: all 0.4s $ease-out-cubic;

  p {
    padding-bottom: $default-spacing * 2;
  }

  .condensed & {
    @media #{$tablet} {
      padding: $default-spacing * 10 $default-spacing * 4 0;
    }
  }

  @media #{$tablet} {
    position: absolute;
    left: 50%;
    padding: $default-spacing * 4;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    .condensed & {
      padding: $default-spacing * 4;
    }
  }
}

.words-page-title__container {
  position: relative;
  width: 100%;
  margin-bottom: $default-spacing * 2;
  transition: all 0.5s $ease-out-cubic;

  @media #{$tablet} {
    .collapsed &,
    .transitioning & {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}

.words-page-title {
  @include font-size(3.5);
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  opacity: 0;

  transform: translateY(100%);
  transition: all 0.5s $ease-out-cubic;

  @media #{$tablet} {
    @include font-size(5);
    line-height: 5.5rem;
  }

  .loaded & {
    transform: translateY(50%);
    opacity: 1;
  }
}

.words-page-title--bottom {
  transform: translateY(-100%);

  .loaded & {
    transform: translateY(-50%);
  }
}

.words-page-title__mask {
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden;
}

.words-page-title__mask--bottom {
  transform: translateY(0);
  top: 50%;
  position: absolute;
}

.words-page-description {
  @include font-size(1);
  line-height: 1.5rem;
  font-family: $font-secondary;
  letter-spacing: 0.5px;
  opacity: 0;
  transform: translateY(-$default-spacing);
  font-kerning: none;
  transition: all 0.5s $ease-out-cubic 0.05s;

  .loaded & {
    opacity: 1;
    transform: translateY(0);
  }

  @media #{$tablet} {
    @include font-size(1.25);
    line-height: 2rem;
    opacity: 0;
    transform: translateY(-$default-spacing);

    .condensed & {
      padding-left: 0;
    }

    .collapsed &,
    .transitioning & {
      opacity: 0;
      transform: translateY(-20px);
    }

    .transitioning & {
      transition: all 0.8s $ease-in-out-cubic;
    }
  }
}
