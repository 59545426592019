.work-slider {
  position: relative;
  z-index: 1;
  opacity: 0;
  width: 100%;

  @media #{$tablet} {
    flex-direction: row;
  }

  .slick-dots {
    bottom: -50px;
  }
}

.work-slide {
  display: block !important;

  img {
    position: relative;
    width: 100%;
    display: block;
    padding: $default-spacing;
    z-index: 1;
  }

  iframe {
    width: 100%;
    position: relative;
  }
}

.work-slide__video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.slick-slide * {
  display: block;
}
