.page-process {
  display: flex;
  padding: 0;

  .condensed .words-page-info:after {
    background: $white;
  }

  .collapsed .words-page-info:after {
    background: transparent;
  }

  .transitioning .words-page-info:after {
    background: transparent;
  }

  .words-page-info {
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 100%;
      transform: translateY(-50%);
      width: 1px;
      transition: all 0.3s;
    }
  }

  @media #{$tablet} {
    overflow: hidden;
  }
}

.process-layout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
}

.process-container {
  position: relative;
  flex: 0;
  transition: all 0.3s $ease-in-out-cubic;

  &.expanded {
    flex: 1;
  }

  #sideBlock {
    opacity: 0.8;
  }

  @media #{$tablet} {
    overflow: hidden;
    background: transparent;
  }
}

.process-list {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: $default-spacing * 2 0;
  margin-bottom: $default-spacing * 4;
  z-index: 1;

  &:hover {
    .process-list-item__content {
      transform: translateX(0);
      opacity: 0.5;
    }
  }

  @media #{$tablet} {
    padding: 0;
    height: 100vh;
    margin-bottom: 0;

    // overflow: scroll;
  }
}

.process-list__back {
  display: none;
  position: absolute;
  top: $default-spacing * 2;
  right: $default-spacing * 2;
  width: 20px;
  height: 20px;
  z-index: 20;
  cursor: pointer;
  transform: scale(1);
  opacity: 1;

  @media #{$tablet} {
    display: block;
  }
}

.process-list-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s;

  &:hover,
  &.active {
    .process-list-item__content {
      transform: translateX(10px);
      opacity: 1;
    }
  }

  @media #{$tablet} {
    opacity: 0;
  }
}

.process-list-item__content {
  @include font-size(3);
  position: relative;
  z-index: 1;
  display: flex;
  color: $white;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  transform: translateX(0);
  opacity: 0.5;
  font-family: 'NoyhBook', Arial, sans-serif;
  transition: all 0.5s $ease-in-out-cubic;
}

.process-list-item__pod {
  @include font-size(3);
  display: flex;
  width: 75px;
  height: 75px;
  border: 1px solid $white;
  align-items: center;
  justify-content: center;
  margin: $default-spacing;
  background: rgba(#60288c, 0.5);
}

.process-list-item__title {
  @include font-size(1.5);
  transform-origin: center left;
  position: relative;
  font-weight: 700;
  z-index: 1;
  color: $color-green--dark;
  transition: all 0.3s;
  @media #{$tablet} {
    @include font-size(3);
  }
}

.process-list-item__description {
  position: relative;
  flex: 1;
  z-index: 1;
  line-height: 1.5rem;
  color: $color-text;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: $default-spacing * 0.5;
}

.process-list-item__meta {
  display: flex;
  justify-content: space-between;
  color: $color-blue;
}

.process-list-item__link {
  text-transform: uppercase;
}

.process-detail__item {
  transition: all 0.5s $ease-in-out-cubic;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.process-content {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 1s $ease-out-cubic;

  @media #{$tablet} {
    height: 100vh;

    &.condensed {
      flex: 1;
      padding: 0 $default-spacing * 3;
    }

    &.collapsed {
      overflow: hidden;
      flex: 0;
      padding: 0;

      .page-title {
        opacity: 0;
      }

      .page-description {
        opacity: 0;
      }
    }
  }
}

.process-detail {
  position: relative;
  padding: calc(var(--vh, 1vh) * 50 - 37.5px) 0;
  margin-top: 75px;
  flex: 0;
  transition: all 0.3s $ease-in-out-cubic;
  overflow: hidden;

  @media #{$tablet} {
    margin-top: 0;
    padding: 50vh 0;
  }

  &.expanded {
    flex: 1;
  }

  .image-pod {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      filter: grayscale(10);
      mix-blend-mode: multiply;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.process-detail__text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  padding: 0 $default-spacing * 4;
  transform: translateX(-50%) translateY(-50%);

  @media #{$tablet} {
    padding: 0 $default-spacing * 6;
  }
}

.process-detail__title {
  @include font-size(2.5);
  opacity: 0;
  font-family: 'NoyhBold', Arial, sans-serif;
  color: $white;

  @media #{$tablet} {
    @include font-size(4);
    line-height: 3.5rem;
    padding-bottom: $default-spacing * 2;
  }
}

.process-detail__description {
  line-height: 1.5rem;
  opacity: 0;
  color: $white;
  font-family: $font-secondary;
  letter-spacing: 0.5px;

  @media #{$tablet} {
    @include font-size(1.25);
    line-height: 2rem;
  }
}

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
