.image-pod {
  position: relative;
  height: 100%;
  overflow: hidden;

  &.left {
    img,
    iframe {
      transform: translateX(-20px);
    }
  }

  &.bottom {
    img,
    iframe {
      transform: translateY(20px);
    }
  }

  &.top {
    img,
    iframe {
      transform: translateY(-20px);
    }
  }

  &.right {
    img,
    iframe {
      transform: translateX(20px);
    }
  }

  .sideBlock {
    position: absolute;
  }

  img,
  .image-cover {
    position: relative;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: all 0.3 $ease-out-cubic 1s;
  }

  .image-cover {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    z-index: 2;
    transform: none !important;
  }

  iframe {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3 $ease-out-cubic 1s;

    .loaded & {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }
}
