.page-alignment {
  padding: 0;
  display: flex;

  @media #{$tablet} {
    min-height: 100vh;
    overflow: hidden;
  }
}

.alignment-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  transform: translateY(30px);
  // overflow: scroll;

  @media #{$tablet} {
    opacity: 0;
    flex-direction: row;
    height: 100vh;
    pointer-events: none;
    &.compact {
      pointer-events: all;
    }
  }
}

.alignment__content-column {
  position: relative;
  min-height: 50vh;
  z-index: 2;

  &--left {
    padding: $default-spacing * 2;

    @media #{$tablet} {
      padding: 0 $default-spacing * 6;
    }
  }
  @media #{$tablet} {
    flex: 1;
    min-width: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

.alignment__content-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: $default-spacing * 3;
}

.alignment-video {
  display: block;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transform: translateX(30px) translateZ(0);

  * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.alignment__video-container {
  flex: 1;
  height: 100%;

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
}
