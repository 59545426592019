#loader {
  position: fixed;
  top: calc(var(--vh, 1vh) * 50);
  left: 50vw;
  width: 120px;
  height: 120px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%);
  z-index: 30;
}

#loader-dot {
  position: absolute;
  top: 30px;
  left: 35px;
  width: 50px;
  height: 50px;
  background: $color-pink;
  border-radius: 100px;
  transform: scale(0);
  z-index: 1;
  .page-about & {
    background: #016d58;
  }
  .page-alignment & {
    background: $color-blue--dark;
  }
  .page-contact & {
    background: #bd52ef;
  }
  .page-process & {
    background: $color-purple;
  }

  .page-words & {
    background: #105568;
  }

  .page-practice & {
    background: $color-pink--dark;
  }
  .page-practice__detail & {
    background: $color-pink--dark;
  }
}

.angle {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 25%;
  height: 4px;
  width: 0%;
  background: $white;
  border-radius: 2px;
}

#loader-underline {
  position: absolute;
  bottom: 0;
  width: 0%;
  height: 4px;
  border-radius: 5px;
  background: $color-pink;
  .page-about & {
    background: #016d58;
  }

  .page-contact & {
    background: #bd52ef;
  }
  .page-alignment & {
    background: $color-blue--dark;
  }

  .page-practice & {
    background: $color-pink--dark;
  }
  .page-practice__detail & {
    background: $color-pink--dark;
  }
  .page-process & {
    background: $color-purple;
  }
  .page-words & {
    background: #105568;
  }
}

#loader-orbit {
  position: absolute;
  width: 10px;
  height: 50px;
  top: 0;
  left: 60px;
  transform: translateX(-50%);
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: $color-pink;
    border-radius: 10px;

    .page-about & {
      background: #016d58;
    }

    .page-alignment & {
      background: $color-blue--dark;
    }
    .page-contact & {
      background: #bd52ef;
    }

    .page-practice & {
      background: $color-pink--dark;
    }

    .page-practice__detail & {
      background: $color-pink--dark;
    }

    .page-process & {
      background: $color-purple;
    }
    .page-words & {
      background: #105568;
    }
  }
}
