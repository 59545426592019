.page-words {
  display: flex;
  padding: 0;

  @media #{$tablet} {
    overflow: hidden;
  }
}

.words-layout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
}

.words-container {
  position: relative;
  flex: 0;
  transition: all 0.3s $ease-in-out-cubic;
  background: rgba(255, 255, 255, 0.8);
  &.expanded {
    flex: 1;
  }

  #sideBlock {
    opacity: 0.8;
  }

  @media #{$tablet} {
    overflow: hidden;
    background: transparent;
  }
}

.word-list {
  position: relative;
  z-index: 1;

  @media #{$tablet} {
    padding-top: $default-spacing * 6;
    height: 100vh;
    overflow: scroll;
  }
}

.word-list__back {
  display: none;
  position: absolute;
  top: $default-spacing * 2;
  right: $default-spacing * 2;
  width: 20px;
  height: 20px;
  z-index: 20;
  cursor: pointer;
  @media #{$tablet} {
    display: block;
  }
}

.word-list-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 200px;
  padding: $default-spacing * 2;
  overflow: hidden;

  &:hover,
  &.active {
    .word-list-item__background {
      opacity: 1;
    }
  }

  @media #{$tablet} {
    opacity: 0;
  }
}

.word-list-item__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #a0d8de;
  padding: 2px;
  opacity: 0;
  transition: all 0.3s $ease-in-out-cubic;
}

.word-list-item__image {
  position: relative;
  z-index: 1;
  img {
    height: 100%;
    display: block;
  }
}

.word-list-item__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'NoyhBook', Arial, sans-serif;
  padding-left: $default-spacing * 2;
}

.word-list-item__title {
  @include font-size(1.25);
  line-height: 1.5rem;
  font-family: $font-primary;
  position: relative;
  z-index: 1;
  padding-bottom: $default-spacing;

  @media #{$tablet} {
    @include font-size(2);
    line-height: 2rem;
  }
}

.word-list-item__description {
  line-height: 1.25rem;
  font-family: $font-secondary;
  position: relative;
  flex: 1;
  z-index: 1;
  color: $color-text;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: $default-spacing * 0.5;

  @media #{$tablet} {
    @include font-size(1.25);
    line-height: 2rem;
  }
}

.word-list-item__meta {
  display: flex;
  justify-content: space-between;
  color: $color-blue;
}

.word-list-item__link {
  text-transform: uppercase;
}

.words-content {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 0.3s $ease-in-out-cubic;

  @media #{$tablet} {
    height: 100vh;

    &.condensed {
      flex: 1;
    }

    &.collapsed {
      overflow: hidden;
      flex: 0;

      .page-title {
        opacity: 0;
      }

      .page-description {
        opacity: 0;
      }
    }
  }
}

.words-detail {
  position: relative;
  background: $white;
  padding: 0;
  flex: 0;
  transition: all 0.3s $ease-in-out-cubic;
  overflow: scroll;

  &.expanded {
    flex: 1;
  }

  .image-pod {
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.words-detail__social {
  display: flex;
  padding: $default-spacing * 2 0 0;
  flex-direction: column;
}

.words-detail__social-icons {
  display: flex;
}

.SocialMediaShareButton {
  margin: 0 $default-spacing * 0.25;
  outline: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

.words-detail__content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 100%;
}

.words-detail__image {
  height: 200px;
}

.words-detail__meta {
  line-height: 1.5rem;
  font-family: 'NoyhBook', Arial, sans-serif;
  padding: $default-spacing * 3 $default-spacing * 2;
  color: $color-text;
  opacity: 0;
  padding-bottom: $default-spacing;

  @media #{$tablet} {
    padding: $default-spacing * 5 $default-spacing * 2 0;
  }
}

.words-detail__date {
  color: $color-blue;
  font-weight: 700;
  line-height: 0;
}

.words-detail__posted-by {
  color: $color-text;
  font-weight: 700;
  line-height: 0;
}

.words-detail__title {
  @include font-size(2);
  line-height: 2.5rem;
  font-family: $font-primary;
  padding: $default-spacing 0;

  @media #{$tablet} {
    @include font-size(3);
  }
}

.words-detail__description {
  line-height: 2rem;
  flex: 1;
  overflow: scroll;
  font-family: $font-secondary;
  letter-spacing: 0.5px;
  padding: $default-spacing * 2;
  margin-top: $default-spacing;
  opacity: 0;
  font-kerning: none;

  p {
    padding-bottom: $default-spacing * 2;
  }

  @media #{$tablet} {
    @include font-size(1.25);
    line-height: 2rem;
  }
}

.words-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  order: 1;
  transition: all 0.5s $ease-out-cubic;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  order: 2;

  .image-pod {
    flex: 1;
  }

  &.image-loaded {
    transform: translateX(0%);
  }
}

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
