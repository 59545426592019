/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/2f2111');

@font-face {
  font-family: 'NoyhBold';
  src: url('fonts/2F2111_3_0.eot');
  src: url('fonts/2F2111_3_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/2F2111_3_0.woff2') format('woff2'),
    url('fonts/2F2111_3_0.woff') format('woff'),
    url('fonts/2F2111_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Noyh';
  src: url('fonts/2F2111_1F_0.eot');
  src: url('fonts/2F2111_1F_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/2F2111_1F_0.woff2') format('woff2'),
    url('fonts/2F2111_1F_0.woff') format('woff'),
    url('fonts/2F2111_1F_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NoyhBook';
  src: url('fonts/2F2111_20_0.eot');
  src: url('fonts/2F2111_20_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/2F2111_20_0.woff2') format('woff2'),
    url('fonts/2F2111_20_0.woff') format('woff'),
    url('fonts/2F2111_20_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NoyhLightItalic';
  src: url('fonts/2F2111_26_0.eot');
  src: url('fonts/2F2111_26_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/2F2111_26_0.woff2') format('woff2'),
    url('fonts/2F2111_26_0.woff') format('woff'),
    url('fonts/2F2111_26_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NoyhLight';
  src: url('fonts/2F2111_27_0.eot');
  src: url('fonts/2F2111_27_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/2F2111_27_0.woff2') format('woff2'),
    url('fonts/2F2111_27_0.woff') format('woff'),
    url('fonts/2F2111_27_0.ttf') format('truetype');
}

body {
  font-family: 'NoyhR', Arial, sans-serif;
}

.animated-text .letter {
  display: inline-block;
}
