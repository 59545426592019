.wp-block-columns {
  display: flex;
}

.wp-block-column {
  flex: 1;
}

.value-columns {
  @media #{$tablet} {
    padding-left: $default-spacing * 2;
  }
}
