$default-animation-duration: 0.2s;

// $ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// Circ
// $ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
// $ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
// $ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Expo
// $ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
// $ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
// $ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

// Quad
// $ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
// $ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
// $ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Quart
// $ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
// $ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

// Quint
// $ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
// $ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Sine
// $ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
// $ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// Back
// $ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
// $ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// $default-transition: all $default-animation-duration $ease-out-quart;

@keyframes tooltip {
  0% {
    transform: translateX(10%);
  }

  50% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(10%);
  }
}

@keyframes drone {
  0% {
    transform: translateY(30%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(30%);
  }
}

@keyframes chopper {
  0% {
    transform: translateY(10%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(10%);
  }
}

@keyframes hook {
  0% {
    height: 5%;
  }

  50% {
    height: 23%;
  }

  100% {
    height: 5%;
  }
}

@keyframes cloud {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes crane {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
