@charset "UTF-8";
@import url("//hello.myfonts.net/count/2f2111");
@import url(../node_modules/rc-texty/assets/index.css);
@import url("https://fonts.googleapis.com/css?family=Alegreya:400,400i");
@keyframes tooltip {
  0% {
    transform: translateX(10%); }
  50% {
    transform: translateX(0%); }
  100% {
    transform: translateX(10%); } }

@keyframes drone {
  0% {
    transform: translateY(30%); }
  50% {
    transform: translateY(0%); }
  100% {
    transform: translateY(30%); } }

@keyframes chopper {
  0% {
    transform: translateY(10%); }
  50% {
    transform: translateY(0%); }
  100% {
    transform: translateY(10%); } }

@keyframes hook {
  0% {
    height: 5%; }
  50% {
    height: 23%; }
  100% {
    height: 5%; } }

@keyframes cloud {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(20%); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0; }
  30% {
    top: 10px;
    opacity: 1; }
  100% {
    top: 25px;
    opacity: 0; } }

@keyframes drop {
  0% {
    top: 5px;
    opacity: 0; }
  30% {
    top: 10px;
    opacity: 1; }
  100% {
    top: 25px;
    opacity: 0; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes crane {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  font-size: 100%; }

html,
body {
  width: 100vw;
  background: #9cd5e7; }
  @media (min-width: 700px) {
    html,
    body {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden; } }

@media (min-width: 700px) {
  #root {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /* enables “momentum” (smooth) scrolling */ } }

a {
  color: #fff; }

@media screen and (max-width: 700px) {
  .main-content {
    top: 75px;
    position: fixed;
    overflow: scroll;
    height: calc(var(--vh, 1vh) * 100 - 75px);
    -webkit-overflow-scrolling: touch; } }

.main-content.home {
  top: 0;
  height: 100vh; }

/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'NoyhBold';
  src: url("fonts/2F2111_3_0.eot");
  src: url("fonts/2F2111_3_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F2111_3_0.woff2") format("woff2"), url("fonts/2F2111_3_0.woff") format("woff"), url("fonts/2F2111_3_0.ttf") format("truetype"); }

@font-face {
  font-family: 'Noyh';
  src: url("fonts/2F2111_1F_0.eot");
  src: url("fonts/2F2111_1F_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F2111_1F_0.woff2") format("woff2"), url("fonts/2F2111_1F_0.woff") format("woff"), url("fonts/2F2111_1F_0.ttf") format("truetype"); }

@font-face {
  font-family: 'NoyhBook';
  src: url("fonts/2F2111_20_0.eot");
  src: url("fonts/2F2111_20_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F2111_20_0.woff2") format("woff2"), url("fonts/2F2111_20_0.woff") format("woff"), url("fonts/2F2111_20_0.ttf") format("truetype"); }

@font-face {
  font-family: 'NoyhLightItalic';
  src: url("fonts/2F2111_26_0.eot");
  src: url("fonts/2F2111_26_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F2111_26_0.woff2") format("woff2"), url("fonts/2F2111_26_0.woff") format("woff"), url("fonts/2F2111_26_0.ttf") format("truetype"); }

@font-face {
  font-family: 'NoyhLight';
  src: url("fonts/2F2111_27_0.eot");
  src: url("fonts/2F2111_27_0.eot?#iefix") format("embedded-opentype"), url("fonts/2F2111_27_0.woff2") format("woff2"), url("fonts/2F2111_27_0.woff") format("woff"), url("fonts/2F2111_27_0.ttf") format("truetype"); }

body {
  font-family: 'NoyhR', Arial, sans-serif; }

.animated-text .letter {
  display: inline-block; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("images/ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "Noyh", Arial, sans-serif;
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "Noyh", Arial, sans-serif;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.alignment-quote {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9; }
  .alignment-quote.active {
    opacity: 1;
    z-index: 10; }
  .alignment-quote .image-pod {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .alignment-quote img {
    filter: grayscale(10);
    mix-blend-mode: multiply; }

.alignment-quote__content {
  color: #fff;
  position: relative;
  z-index: 2;
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.alignment-quote__text {
  font-size: 12.5px;
  font-size: 1.25rem;
  line-height: 12.5px;
  line-height: 1.5625rem;
  line-height: 1.5rem;
  font-family: "Alegreya", Arial, sans-serif;
  padding: 20px 0;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s; }
  .active .alignment-quote__text {
    transform: translateY(0);
    opacity: 1; }
  @media (min-width: 700px) {
    .alignment-quote__text {
      font-size: 20px;
      font-size: 2rem;
      line-height: 20px;
      line-height: 2.5rem; } }

.alignment-quote__attribution {
  text-transform: uppercase;
  font-family: "Noyh", Arial, sans-serif;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s; }
  .active .alignment-quote__attribution {
    transform: translateY(0);
    opacity: 1; }

.arrow {
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  height: 30px;
  width: 15px;
  transform: translate(-50%);
  transition: all 0.3s ease-out 2s; }
  .loaded .arrow {
    opacity: 1; }

.arrow span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite; }

.arrow span:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-8px, -8px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(8px, 8px); } }

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover; }

#fireflies {
  position: fixed;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  transform: scale(0.5);
  transform-origin: 0 0;
  z-index: 2;
  mix-blend-mode: overlay; }

.color-filter {
  mix-blend-mode: color; }

.color-multiply {
  mix-blend-mode: multiply; }

.color-exclusion {
  mix-blend-mode: exclusion; }

.color-soft-light {
  mix-blend-mode: soft-light; }

.color-overlay {
  mix-blend-mode: overlay; }

.color-saturation {
  mix-blend-mode: saturation; }

.color-difference {
  mix-blend-mode: difference; }

.bump-container {
  width: 100%; }
  .bump-container .arrow {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    z-index: 1; }

.bump {
  height: 0;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  width: 100%;
  display: none; }
  .loaded .bump {
    height: 50px; }
  @media (min-width: 700px) {
    .bump {
      position: absolute;
      bottom: 100%;
      display: block; } }

.contact-info {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-top: 50px; }
  .contact-info a,
  .contact-info a:visited {
    color: #fff;
    text-decoration: none; }
  .greater-navigation .contact-info {
    margin-top: auto; }
  .contact-info #bottomBump {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media screen and (min-height: 500px) {
    .contact-info {
      width: 100%;
      height: auto; } }

.contact-info__container {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 30px 10px 10px;
  width: 100%;
  height: 100%;
  justify-content: space-around; }
  @media (min-width: 700px) and (min-height: 500px) {
    .contact-info__container {
      flex-direction: row; } }

.contact-info__column {
  font-size: 8.625px;
  font-size: 0.8625rem;
  line-height: 8.625px;
  line-height: 1.07812rem;
  display: flex;
  font-family: 'NoyhLight', Arial, sans-serif;
  color: #fff;
  padding: 0 10px; }
  @media (min-width: 700px) {
    .contact-info__column {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      padding: 0 10px 10px; } }

.contact-info__social {
  display: flex;
  padding-top: 10px; }

.contact-info__tagline {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'NoyhLightItalic', Arial, sans-serif;
  padding: 10px 0; }

.contact-info__column--social {
  flex-direction: column; }

.contact-info__label {
  font-family: 'NoyhLightItalic', Arial, sans-serif;
  color: #fff;
  display: none; }
  @media (min-width: 700px) and (min-height: 600px) {
    .contact-info__label {
      display: inline; } }

.contact-info__divider {
  margin: 8px 20px;
  flex: 1;
  min-width: 30px;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  display: none; }
  @media (min-width: 700px) and (min-height: 500px) {
    .contact-info__divider {
      display: inline; } }

.contact-info__content {
  color: #fff; }

a.email-link {
  color: #108754; }

.social-link {
  max-width: 20px;
  margin-right: 10px; }
  .social-link img {
    width: 100%; }

.contact-slide {
  position: absolute;
  padding: 0 20px;
  width: 100vw;
  max-width: 800px;
  height: calc(var(--vh, 1vh) * 100 - 150px);
  top: 0;
  display: flex;
  color: #fff;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  transform: translateY(20px); }
  @media (min-width: 700px) {
    .contact-slide {
      height: calc(100vh - 75px); } }
  .contact-slide .contact-page__subtitle {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .contact-slide .contact-page__inputs {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s; }
  .contact-slide .contact-page__title {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }
  .contact-slide .contact-page__options {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s; }
  .contact-slide .contact-nav {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .contact-slide .contact-submit {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .contact-slide.active {
    pointer-events: all; }
    .contact-slide.active .contact-nav,
    .contact-slide.active .contact-page__inputs,
    .contact-slide.active .contact-page__subtitle,
    .contact-slide.active .contact-page__title,
    .contact-slide.active .contact-page__options,
    .contact-slide.active .contact-submit {
      opacity: 1;
      transform: translateY(0); }
      .contact-slide.active .contact-nav.disabled,
      .contact-slide.active .contact-page__inputs.disabled,
      .contact-slide.active .contact-page__subtitle.disabled,
      .contact-slide.active .contact-page__title.disabled,
      .contact-slide.active .contact-page__options.disabled,
      .contact-slide.active .contact-submit.disabled {
        pointer-events: none;
        opacity: 0.5; }

.contact-page__subtitle {
  font-size: 10px;
  font-size: 1rem;
  line-height: 10px;
  line-height: 1.25rem;
  font-family: "Alegreya", Arial, sans-serif;
  letter-spacing: 1px;
  opacity: 0;
  padding-bottom: 10px;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s; }
  @media (min-width: 700px) {
    .contact-page__subtitle {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 15px;
      line-height: 1.875rem; } }

.contact-nav,
.contact-submit {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  text-transform: uppercase;
  font-family: "Noyh", Arial, sans-serif;
  cursor: pointer;
  color: #fff;
  padding: 20px 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: none;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  .contact-nav svg,
  .contact-submit svg {
    margin: 0 10px; }

.contact-nav svg {
  margin-left: 0; }

.contact-page__title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 20px;
  line-height: 2.5rem;
  line-height: 2rem;
  font-family: 'NoyhBold', Arial, sans-serif; }
  @media (min-width: 700px) {
    .contact-page__title {
      font-size: 40px;
      font-size: 4rem;
      line-height: 40px;
      line-height: 5rem; } }

.contact-page__inputs {
  display: flex;
  flex-direction: column; }
  .contact-page__inputs input,
  .contact-page__inputs button,
  .contact-page__inputs textarea {
    font-size: 12.5px;
    font-size: 1.25rem;
    line-height: 12.5px;
    line-height: 1.5625rem;
    outline: none;
    resize: none;
    -webkit-appearance: none;
    font-family: "Noyh", Arial, sans-serif; }
    .contact-page__inputs input::-webkit-input-placeholder,
    .contact-page__inputs button::-webkit-input-placeholder,
    .contact-page__inputs textarea::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.6); }
    .contact-page__inputs input::-moz-placeholder,
    .contact-page__inputs button::-moz-placeholder,
    .contact-page__inputs textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.6); }
    .contact-page__inputs input:-ms-input-placeholder,
    .contact-page__inputs button:-ms-input-placeholder,
    .contact-page__inputs textarea:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, 0.6); }
    .contact-page__inputs input:-moz-placeholder,
    .contact-page__inputs button:-moz-placeholder,
    .contact-page__inputs textarea:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.6); }
  .contact-page__inputs [type='text'],
  .contact-page__inputs textarea {
    background: transparent;
    color: #fff;
    width: 100%;
    border: 2px solid #fff;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 20px; }
  .contact-page__inputs [type='submit'] {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 15px;
    line-height: 1.875rem;
    background: transparent;
    color: #fff;
    font-family: "Noyh", Arial, sans-serif;
    border: 0;
    text-align: left; }

.contact-page__options {
  display: flex;
  flex-direction: column;
  padding-top: 10px; }
  @media (min-width: 700px) {
    .contact-page__options {
      flex-direction: row; } }

.contact-page__option {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  width: 100%;
  cursor: pointer;
  font-family: "Noyh", Arial, sans-serif;
  background: transparent;
  color: #fff;
  padding: 10px 20px;
  margin: 10px 20px 0 0;
  border: 2px solid #fff;
  border-radius: 100px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none; }
  .contact-page__option:last-child {
    margin-right: 0; }
  .contact-page__option:hover, .contact-page__option.selected {
    background: #fff;
    color: #9cd5e7; }

.content-slide-item__title {
  font-size: 30px;
  font-size: 3rem;
  line-height: 30px;
  line-height: 3.75rem;
  color: #fff;
  font-family: 'NoyhBold', Arial, sans-serif;
  opacity: 0;
  padding-bottom: 20px;
  transform: translateY(-10px) translateZ(0);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }

.content-slide-item__description {
  line-height: 2rem;
  font-family: "Alegreya", Arial, sans-serif;
  font-kerning: none;
  opacity: 0;
  transform: translateY(-10px) translateZ(0);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s; }
  @media (min-width: 700px) {
    .content-slide-item__description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem; } }

.work-slider__placeholder {
  position: relative;
  width: 100%; }
  .work-slider__placeholder .slick-slider,
  .work-slider__placeholder .slick-list,
  .work-slider__placeholder .slick-track {
    height: 100%; }
  .work-slider__placeholder .slick-slide > div {
    height: 100%; }
  .work-slider__placeholder .slick-slide .content-slide-item {
    height: 100%; }
  .work-slider__placeholder .slick-slide img {
    max-height: 100%; }
  .work-slider__placeholder .content-slide-item {
    display: flex !important;
    align-items: center;
    justify-content: center; }

.custom-dots__placeholder {
  position: relative;
  width: 100%;
  min-height: 60px; }
  .custom-dots__placeholder .custom-dots {
    padding: 10px;
    margin-top: 20px; }

.content-slide-item {
  color: #fff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }
  .content-slide-item img,
  .content-slide-item iframe {
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .content-slide-item.active img,
  .content-slide-item.active iframe {
    transform: translateY(0);
    opacity: 1; }
  .content-slide-item.active .content-slide-item__title {
    transform: translateY(0);
    opacity: 1; }
  .content-slide-item.active .content-slide-item__description {
    transform: translateY(0);
    opacity: 1; }
  @media (min-width: 700px) {
    .content-slide-item.active {
      transform: translateY(0px); } }

.custom-dots {
  opacity: 0;
  transform: translateY(30px);
  margin: auto;
  position: relative;
  text-align: center;
  margin-top: 60px;
  padding-top: 60px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s; }
  .loaded .custom-dots {
    opacity: 1;
    transform: translateY(0); }
  .custom-dots:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background: rgba(255, 255, 255, 0.5); }

.dot {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  flex: 1;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .dot:before {
    content: '';
    display: block;
    position: relative;
    width: 10px;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: transparent;
    border-radius: 10px;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .dot:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .dot.active:before {
    background: rgba(0, 0, 0, 0.5);
    border-color: transparent; }
  .dot:hover:before {
    opacity: 0; }
  .dot:hover:after {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    opacity: 1; }
  .dot:hover .dot-title {
    top: 25px;
    opacity: 1; }

.dot-title {
  position: absolute;
  display: inline-block;
  pointer-events: none;
  text-transform: uppercase;
  padding-top: 10px;
  color: #fff;
  font-family: "Noyh", Arial, sans-serif;
  top: 30px;
  left: 10px;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  white-space: nowrap; }
  .dot-title:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -10px;
    height: 15px;
    width: 1px;
    background: #fff; }

.icon-scroll {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: 10px;
  transition: all 0.3s; }

.icon-scroll {
  display: none;
  width: 20px;
  height: 20px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll; }
  .icon-scroll.compact {
    opacity: 0; }
  @media (min-width: 700px) {
    .icon-scroll {
      display: block; } }

.icon-scroll:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url("images/icons/arrow.svg") no-repeat 0 0;
  width: 20px;
  height: 20px;
  background-size: contain; }

@keyframes scroll {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

.image-pod {
  position: relative;
  height: 100%;
  overflow: hidden; }
  .image-pod.left img,
  .image-pod.left iframe {
    transform: translateX(-20px); }
  .image-pod.bottom img,
  .image-pod.bottom iframe {
    transform: translateY(20px); }
  .image-pod.top img,
  .image-pod.top iframe {
    transform: translateY(-20px); }
  .image-pod.right img,
  .image-pod.right iframe {
    transform: translateX(20px); }
  .image-pod .sideBlock {
    position: absolute; }
  .image-pod img,
  .image-pod .image-cover {
    position: relative;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: all 0.3 cubic-bezier(0.215, 0.61, 0.355, 1) 1s; }
  .image-pod .image-cover {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    z-index: 2;
    transform: none !important; }
  .image-pod iframe {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3 cubic-bezier(0.215, 0.61, 0.355, 1) 1s; }
    .loaded .image-pod iframe {
      opacity: 1;
      transform: translateX(0) translateY(0); }

#loader {
  position: fixed;
  top: calc(var(--vh, 1vh) * 50);
  left: 50vw;
  width: 120px;
  height: 120px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%);
  z-index: 30; }

#loader-dot {
  position: absolute;
  top: 30px;
  left: 35px;
  width: 50px;
  height: 50px;
  background: #de8990;
  border-radius: 100px;
  transform: scale(0);
  z-index: 1; }
  .page-about #loader-dot {
    background: #016d58; }
  .page-alignment #loader-dot {
    background: #248dc1; }
  .page-contact #loader-dot {
    background: #bd52ef; }
  .page-process #loader-dot {
    background: #60288c; }
  .page-words #loader-dot {
    background: #105568; }
  .page-practice #loader-dot {
    background: #ce1974; }
  .page-practice__detail #loader-dot {
    background: #ce1974; }

.angle {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 25%;
  height: 4px;
  width: 0%;
  background: #fff;
  border-radius: 2px; }

#loader-underline {
  position: absolute;
  bottom: 0;
  width: 0%;
  height: 4px;
  border-radius: 5px;
  background: #de8990; }
  .page-about #loader-underline {
    background: #016d58; }
  .page-contact #loader-underline {
    background: #bd52ef; }
  .page-alignment #loader-underline {
    background: #248dc1; }
  .page-practice #loader-underline {
    background: #ce1974; }
  .page-practice__detail #loader-underline {
    background: #ce1974; }
  .page-process #loader-underline {
    background: #60288c; }
  .page-words #loader-underline {
    background: #105568; }

#loader-orbit {
  position: absolute;
  width: 10px;
  height: 50px;
  top: 0;
  left: 60px;
  transform: translateX(-50%);
  opacity: 0; }
  #loader-orbit::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #de8990;
    border-radius: 10px; }
    .page-about #loader-orbit::before {
      background: #016d58; }
    .page-alignment #loader-orbit::before {
      background: #248dc1; }
    .page-contact #loader-orbit::before {
      background: #bd52ef; }
    .page-practice #loader-orbit::before {
      background: #ce1974; }
    .page-practice__detail #loader-orbit::before {
      background: #ce1974; }
    .page-process #loader-orbit::before {
      background: #60288c; }
    .page-words #loader-orbit::before {
      background: #105568; }

.greater-navigation {
  position: relative;
  font-family: 'NoyhBold', Arial, sans-serif;
  z-index: 12;
  pointer-events: none; }
  .greater-navigation .logo svg path,
  .greater-navigation .logo svg #period circle {
    fill: #de8990; }
  .greater-navigation .logo svg circle,
  .greater-navigation .logo svg line {
    stroke: #de8990; }
  .greater-navigation .logo svg #period circle {
    stroke: transparent; }
  .greater-navigation.alignment .logo svg path,
  .greater-navigation.alignment .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.alignment .logo svg circle,
  .greater-navigation.alignment .logo svg line {
    stroke: #fff; }
  .greater-navigation.about .logo svg path,
  .greater-navigation.about .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.about .logo svg circle,
  .greater-navigation.about .logo svg line {
    stroke: #fff; }
  .greater-navigation.work .logo svg path,
  .greater-navigation.work .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.work .logo svg circle,
  .greater-navigation.work .logo svg line {
    stroke: #fff; }
  .greater-navigation.process .logo svg path,
  .greater-navigation.process .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.process .logo svg circle,
  .greater-navigation.process .logo svg line {
    stroke: #fff; }
  .greater-navigation.battle-tested .logo svg path,
  .greater-navigation.battle-tested .logo svg #period circle {
    fill: #248dc1; }
  .greater-navigation.battle-tested .logo svg circle,
  .greater-navigation.battle-tested .logo svg line {
    stroke: #248dc1; }
  .greater-navigation.contact .logo svg path,
  .greater-navigation.contact .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.contact .logo svg circle,
  .greater-navigation.contact .logo svg line {
    stroke: #fff; }
  .greater-navigation.writing .logo svg path,
  .greater-navigation.writing .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.writing .logo svg circle,
  .greater-navigation.writing .logo svg line {
    stroke: #fff; }
  .greater-navigation.writing-detail .logo svg path,
  .greater-navigation.writing-detail .logo svg #period circle {
    fill: #de8990; }
  .greater-navigation.writing-detail .logo svg circle,
  .greater-navigation.writing-detail .logo svg line {
    stroke: #de8990; }
  .greater-navigation.open .logo svg path,
  .greater-navigation.open .logo svg #period circle {
    fill: #fff; }
  .greater-navigation.open .logo svg circle,
  .greater-navigation.open .logo svg line {
    stroke: #fff; }
  .greater-navigation.about .hamburger, .greater-navigation.contact .hamburger, .greater-navigation.writing .hamburger, .greater-navigation.battle-tested .hamburger, .greater-navigation.process .hamburger, .greater-navigation.work .hamburger, .greater-navigation.alignment .hamburger {
    width: 30px; }
  .greater-navigation.about .hamburger-helper, .greater-navigation.contact .hamburger-helper, .greater-navigation.writing .hamburger-helper, .greater-navigation.battle-tested .hamburger-helper, .greater-navigation.process .hamburger-helper, .greater-navigation.work .hamburger-helper, .greater-navigation.alignment .hamburger-helper {
    display: none; }
  .greater-navigation .contact-info__container {
    opacity: 0;
    transform: translateY(30px);
    pointer-events: auto; }

.navigation-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .navigation-background #backEnd,
  .navigation-background #frontEnd {
    visibility: hidden; }

.logo {
  cursor: pointer;
  width: 150px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  pointer-events: auto; }
  .logo svg {
    width: 100%; }

.greater-navigation__header {
  top: 0;
  position: fixed;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3; }

.hamburger {
  width: 140px;
  height: 30px;
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  transform: translateY(10px); }
  .hamburger.load-anim {
    transform: translateY(0px);
    opacity: 1; }

.hamburger-lines {
  width: 20px;
  height: 20px;
  justify-self: flex-end; }

.hamburger-helper {
  font-size: 1rem;
  position: relative;
  flex: 1;
  text-align: right;
  text-transform: uppercase;
  color: #fff;
  padding-right: 30px;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger-helper:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjQsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yNC4yOTEsMTQuMjc2TDE0LjcwNSw0LjY5Yy0wLjg3OC0wLjg3OC0yLjMxNy0wLjg3OC0zLjE5NSwwbC0wLjgsMC44Yy0wLjg3OCwwLjg3Ny0wLjg3OCwyLjMxNiwwLDMuMTk0DQoJTDE4LjAyMywxNmwtNy4zMTQsNy4zMTRjLTAuODc4LDAuODc5LTAuODc4LDIuMzE3LDAsMy4xOTRsMC44LDAuOGMwLjg3OCwwLjg3OSwyLjMxNywwLjg3OSwzLjE5NSwwbDkuNTg2LTkuNTg2DQoJYzAuNDcyLTAuNDcyLDAuNjgzLTEuMTA0LDAuNjQ3LTEuNzI0QzI0Ljk3MywxNS4zOCwyNC43NjQsMTQuNzQ4LDI0LjI5MSwxNC4yNzZ6Ii8+DQo8L3N2Zz4NCg==") no-repeat scroll 0 0 transparent;
    background-size: contain;
    animation: loopArrow 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite; }

.hamburger-line {
  width: 20px;
  height: 2px;
  background: #fff;
  margin-bottom: 7px;
  border-radius: 5px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger-line:last-child {
    margin: 0; }
  @media (min-width: 700px) {
    .writing .hamburger-line {
      background: #00ce9b; } }
  .open .hamburger-line {
    background: #fff; }

.open .burger-top {
  width: 70%;
  transform: rotate(-45deg) translateY(2px) translateX(-4px); }

.open .burger-bottom {
  width: 70%;
  transform: rotate(45deg) translateY(-2px) translateX(-4px); }

.open .hamburger-helper {
  transform: translateY(10px);
  opacity: 0; }

.greater-navigation__content {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 2;
  align-items: center;
  flex-direction: row; }
  @media screen and (min-height: 500px) {
    .greater-navigation__content {
      flex-direction: column; } }

.greater-navigation__content-list {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  max-height: 450px;
  padding: 75px 0 40px;
  width: 50%;
  z-index: 1; }
  @media screen and (min-height: 500px) {
    .greater-navigation__content-list {
      width: 100%; } }
  @media (min-width: 700px) {
    .greater-navigation__content-list {
      max-height: none; } }

.greater-navigation__content-list__item {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  pointer-events: auto;
  position: relative;
  color: #fff;
  letter-spacing: 2px;
  cursor: pointer;
  transform: translateY(10px);
  opacity: 0; }
  .greater-navigation__content-list__item a,
  .greater-navigation__content-list__item a:visited {
    text-decoration: none;
    color: #216591;
    transition: all 0.3s ease-out; }
  .greater-navigation__content-list__item:after, .greater-navigation__content-list__item:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 110%;
    width: 20px;
    height: 3px;
    border-radius: 3px;
    background: #fff;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .greater-navigation__content-list__item:hover a {
    color: #fff; }
  .greater-navigation__content-list__item:hover:after, .greater-navigation__content-list__item:hover:before {
    background: #fff;
    transform: translateY(-4.5px) translateX(20px) rotate(30deg);
    width: 20px;
    opacity: 1; }
  .greater-navigation__content-list__item:hover:before {
    transform: translateY(4.5px) translateX(20px) rotate(-30deg); }
  @media screen and (min-width: 500px) {
    .greater-navigation__content-list__item {
      font-size: 20px;
      font-size: 2rem;
      line-height: 20px;
      line-height: 2.5rem; } }

@keyframes loopArrow {
  0% {
    opacity: 0;
    transform: translateX(-10px); }
  50% {
    opacity: 1;
    transform: translateX(0px); }
  100% {
    opacity: 0;
    transform: translateX(10px); } }

.page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }

.page-content {
  position: relative; }

.home-page-info {
  z-index: 10; }

.page-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  color: #fff;
  padding: 20px;
  transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  .page-info p {
    padding-bottom: 20px; }
  @media (min-width: 700px) {
    .page-info {
      position: absolute;
      left: 50%;
      top: 50%;
      padding: 100px 40px;
      transform: translateX(-50%) translateY(-50%); } }

.page-title__container {
  position: relative;
  width: 100%;
  margin-bottom: 20px; }
  @media (min-width: 700px) {
    .compact .page-title__container {
      opacity: 0;
      transform: translateY(-20px); } }

.page-title {
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 35px;
  line-height: 4.375rem;
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media (min-width: 700px) and (min-height: 769px) {
    .page-title {
      font-size: 50px;
      font-size: 5rem;
      line-height: 50px;
      line-height: 6.25rem;
      line-height: 5.5rem; } }
  .loaded .page-title {
    transform: translateY(50%);
    opacity: 1; }

.page-title--bottom {
  transform: translateY(-100%); }
  .loaded .page-title--bottom {
    transform: translateY(-50%); }

.page-title__mask {
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden; }

.page-title__mask--bottom {
  transform: translateY(0);
  top: 50%;
  position: absolute; }

.page-description {
  line-height: 2rem;
  font-family: "Alegreya", Arial, sans-serif;
  transform: translateY(-30px) translateZ(0);
  opacity: 0; }
  @media (min-width: 700px) and (min-height: 769px) {
    .page-description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem; }
      .compact .page-description {
        opacity: 0 !important;
        transform: translateY(-20px) !important; } }

.slick-dots {
  bottom: 0; }
  .slick-dots li button:before {
    color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    opacity: 1;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 10px; }
  .slick-dots .slick-active button:before {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid transparent; }

.transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 15; }
  .transition .scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.blue > g > g:nth-child(1) path,
.blue > g > g:nth-child(3) path {
  fill: #9cd5e7; }

.blue > g > g:nth-child(2) path,
.blue > g > g:nth-child(4) path {
  fill: #4ab2d3; }

.pink > g > g:nth-child(1) path,
.pink > g > g:nth-child(3) path {
  fill: #ff33ad; }

.pink > g > g:nth-child(2) path,
.pink > g > g:nth-child(4) path {
  fill: #cc007a; }

.green > g > g:nth-child(1) path,
.green > g > g:nth-child(3) path {
  fill: #7ecaab; }

.green > g > g:nth-child(2) path,
.green > g > g:nth-child(4) path {
  fill: #42a07a; }

.words-page-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  color: #fff;
  padding: 20px;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .words-page-info p {
    padding-bottom: 20px; }
  @media (min-width: 700px) {
    .condensed .words-page-info {
      padding: 100px 40px 0; } }
  @media (min-width: 700px) {
    .words-page-info {
      position: absolute;
      left: 50%;
      padding: 40px;
      top: 50%;
      transform: translateX(-50%) translateY(-50%); }
      .condensed .words-page-info {
        padding: 40px; } }

.words-page-title__container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media (min-width: 700px) {
    .collapsed .words-page-title__container,
    .transitioning .words-page-title__container {
      opacity: 0;
      transform: translateY(-20px); } }

.words-page-title {
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 35px;
  line-height: 4.375rem;
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media (min-width: 700px) {
    .words-page-title {
      font-size: 50px;
      font-size: 5rem;
      line-height: 50px;
      line-height: 6.25rem;
      line-height: 5.5rem; } }
  .loaded .words-page-title {
    transform: translateY(50%);
    opacity: 1; }

.words-page-title--bottom {
  transform: translateY(-100%); }
  .loaded .words-page-title--bottom {
    transform: translateY(-50%); }

.words-page-title__mask {
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden; }

.words-page-title__mask--bottom {
  transform: translateY(0);
  top: 50%;
  position: absolute; }

.words-page-description {
  font-size: 10px;
  font-size: 1rem;
  line-height: 10px;
  line-height: 1.25rem;
  line-height: 1.5rem;
  font-family: "Alegreya", Arial, sans-serif;
  letter-spacing: 0.5px;
  opacity: 0;
  transform: translateY(-10px);
  font-kerning: none;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.05s; }
  .loaded .words-page-description {
    opacity: 1;
    transform: translateY(0); }
  @media (min-width: 700px) {
    .words-page-description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem;
      opacity: 0;
      transform: translateY(-10px); }
      .condensed .words-page-description {
        padding-left: 0; }
      .collapsed .words-page-description,
      .transitioning .words-page-description {
        opacity: 0;
        transform: translateY(-20px); }
      .transitioning .words-page-description {
        transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); } }

.work-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 0;
  margin: 0; }
  .work-item:hover .work-item__content {
    opacity: 1; }
  .work-item:hover .work-item__client {
    opacity: 1;
    transform: translateY(0); }
  .work-item:hover .work-item__project {
    opacity: 1;
    transform: translateY(0); }
  .work-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media screen and (max-width: 600px) {
    .work-item .work-item__content {
      opacity: 1; }
    .work-item .work-item__client {
      opacity: 1;
      transform: translateY(0); }
    .work-item .work-item__project {
      opacity: 1;
      transform: translateY(0); } }
  @media (min-width: 700px) {
    .work-item {
      width: 50%; } }
  @media screen and (min-width: 750px) {
    .work-item {
      width: 33.333%; } }

.work-item__background {
  width: 100%;
  height: 100%; }

.work-item__content {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 51, 173, 0) 50%, rgba(255, 51, 173, 0.8));
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-left: 20px; }
  @media (min-width: 700px) {
    .work-item__content {
      background: rgba(255, 51, 173, 0.8);
      padding-left: 40px; } }

.work-item__client {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  font-family: "Noyh", Arial, sans-serif;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.work-item__project {
  font-size: 20px;
  font-size: 2rem;
  line-height: 20px;
  line-height: 2.5rem;
  font-family: "Alegreya", Arial, sans-serif;
  padding-bottom: 30px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s; }

.work-slider {
  position: relative;
  z-index: 1;
  opacity: 0;
  width: 100%; }
  @media (min-width: 700px) {
    .work-slider {
      flex-direction: row; } }
  .work-slider .slick-dots {
    bottom: -50px; }

.work-slide {
  display: block !important; }
  .work-slide img {
    position: relative;
    width: 100%;
    display: block;
    padding: 10px;
    z-index: 1; }
  .work-slide iframe {
    width: 100%;
    position: relative; }

.work-slide__video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%; }
  .work-slide__video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.slick-slide * {
  display: block; }

.page-about {
  display: flex;
  padding: 0; }
  @media (min-width: 700px) {
    .page-about {
      overflow: hidden; } }

.about-layout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  min-height: 100vh; }
  @media (min-width: 700px) {
    .about-layout {
      flex-direction: row; } }

.about-content {
  position: relative;
  flex: 3;
  order: 2;
  display: flex;
  align-items: center; }
  @media (min-width: 700px) {
    .about-content {
      height: 100vh; } }

.about-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  order: 1;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  order: 2; }
  .about-image .image-pod {
    flex: 1; }
  .about-image.image-loaded {
    transform: translateX(0%); }
  @media (min-width: 700px) {
    .about-image {
      flex: 2; } }

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page-alignment {
  padding: 0;
  display: flex; }
  @media (min-width: 700px) {
    .page-alignment {
      min-height: 100vh;
      overflow: hidden; } }

.alignment-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  transform: translateY(30px); }
  @media (min-width: 700px) {
    .alignment-container {
      opacity: 0;
      flex-direction: row;
      height: 100vh;
      pointer-events: none; }
      .alignment-container.compact {
        pointer-events: all; } }

.alignment__content-column {
  position: relative;
  min-height: 50vh;
  z-index: 2; }
  .alignment__content-column--left {
    padding: 20px; }
    @media (min-width: 700px) {
      .alignment__content-column--left {
        padding: 0 60px; } }
  @media (min-width: 700px) {
    .alignment__content-column {
      flex: 1;
      min-width: 50%;
      justify-content: center;
      display: flex;
      flex-direction: column; } }

.alignment__content-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px; }

.alignment-video {
  display: block;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transform: translateX(30px) translateZ(0); }
  .alignment-video * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.alignment__video-container {
  flex: 1;
  height: 100%; }
  .alignment__video-container .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; }

.page-contact {
  display: flex;
  flex-direction: column;
  padding-top: 0; }

.contact-error {
  font-size: 12.5px;
  font-size: 1.25rem;
  line-height: 12.5px;
  line-height: 1.5625rem;
  color: #de8990; }

.contact-form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: auto;
  flex: 1; }

.contact-actions {
  display: flex;
  justify-content: space-between; }

.contact-form__success {
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  flex-direction: column;
  width: 100%;
  color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center; }
  .contact-form__success .page-title {
    opacity: 0;
    transform: translateX(0) translateY(0); }
  .contact-form__success .page-description {
    text-align: center;
    padding: 20px 10px; }
    .contact-form__success .page-description p {
      margin: 10px 0; }

.contact-page__success-title {
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 35px;
  line-height: 4.375rem;
  line-height: 4rem;
  font-family: 'NoyhBold', Arial, sans-serif;
  font-kerning: none;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media (min-width: 700px) {
    .contact-page__success-title {
      font-size: 50px;
      font-size: 5rem;
      line-height: 50px;
      line-height: 6.25rem;
      line-height: 5.5rem; } }
  .success .contact-page__success-title {
    transform: translateY(0);
    opacity: 1; }

.contact-page__success-description {
  font-size: 12.5px;
  font-size: 1.25rem;
  line-height: 12.5px;
  line-height: 1.5625rem;
  text-align: center;
  line-height: 2rem;
  font-family: "Alegreya", Arial, sans-serif;
  transform: translateY(30px) translateZ(0);
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }
  .contact-page__success-description p {
    padding-bottom: 10px; }
  .success .contact-page__success-description {
    transform: translateY(0);
    opacity: 1; }

.contact-container {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.column {
  flex: 1; }

hr {
  border: 0;
  background: rgba(0, 0, 0, 0.15);
  height: 1px;
  margin: 10px 0; }

.page-home {
  padding: 0;
  display: flex; }

.home-intro {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 20px;
  display: flex;
  z-index: 2; }
  .home-intro path,
  .home-intro #period circle {
    fill: #29abe2; }
  .home-intro circle,
  .home-intro line {
    stroke: #29abe2; }

.intro-logo,
.home-tagline {
  flex: 1;
  width: 100%;
  height: 100%; }

.intro-logo {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.home-logo {
  padding: 20px;
  max-width: 320px;
  width: 100%;
  height: 100%; }

.home-tagline {
  display: flex;
  align-items: center; }

.home-tagline__content {
  border-left: 1px solid #29abe2;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.home-tagline__line {
  font-size: 8.675px;
  font-size: 0.8675rem;
  line-height: 8.675px;
  line-height: 1.08438rem;
  font-family: "Alegreya", Arial, sans-serif;
  color: #29abe2;
  opacity: 0; }
  @media (min-width: 700px) {
    .home-tagline__line {
      font-size: 20px;
      font-size: 2rem;
      line-height: 20px;
      line-height: 2.5rem; } }

.wp-block-columns {
  display: flex; }

.wp-block-column {
  flex: 1; }

@media (min-width: 700px) {
  .value-columns {
    padding-left: 20px; } }

.page-no-match {
  background: yellow; }

.page-process {
  display: flex;
  padding: 0; }
  .page-process .condensed .words-page-info:after {
    background: #fff; }
  .page-process .collapsed .words-page-info:after {
    background: transparent; }
  .page-process .transitioning .words-page-info:after {
    background: transparent; }
  .page-process .words-page-info {
    z-index: 10; }
    .page-process .words-page-info:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 100%;
      transform: translateY(-50%);
      width: 1px;
      transition: all 0.3s; }
  @media (min-width: 700px) {
    .page-process {
      overflow: hidden; } }

.process-layout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column; }
  @media (min-width: 700px) {
    .process-layout {
      flex-direction: row;
      height: 100vh;
      overflow: hidden; } }

.process-container {
  position: relative;
  flex: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .process-container.expanded {
    flex: 1; }
  .process-container #sideBlock {
    opacity: 0.8; }
  @media (min-width: 700px) {
    .process-container {
      overflow: hidden;
      background: transparent; } }

.process-list {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 40px;
  z-index: 1; }
  .process-list:hover .process-list-item__content {
    transform: translateX(0);
    opacity: 0.5; }
  @media (min-width: 700px) {
    .process-list {
      padding: 0;
      height: 100vh;
      margin-bottom: 0; } }

.process-list__back {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 20;
  cursor: pointer;
  transform: scale(1);
  opacity: 1; }
  @media (min-width: 700px) {
    .process-list__back {
      display: block; } }

.process-list-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s; }
  .process-list-item:hover .process-list-item__content, .process-list-item.active .process-list-item__content {
    transform: translateX(10px);
    opacity: 1; }
  @media (min-width: 700px) {
    .process-list-item {
      opacity: 0; } }

.process-list-item__content {
  font-size: 30px;
  font-size: 3rem;
  line-height: 30px;
  line-height: 3.75rem;
  position: relative;
  z-index: 1;
  display: flex;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  transform: translateX(0);
  opacity: 0.5;
  font-family: 'NoyhBook', Arial, sans-serif;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }

.process-list-item__pod {
  font-size: 30px;
  font-size: 3rem;
  line-height: 30px;
  line-height: 3.75rem;
  display: flex;
  width: 75px;
  height: 75px;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background: rgba(96, 40, 140, 0.5); }

.process-list-item__title {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  transform-origin: center left;
  position: relative;
  font-weight: 700;
  z-index: 1;
  color: #108754;
  transition: all 0.3s; }
  @media (min-width: 700px) {
    .process-list-item__title {
      font-size: 30px;
      font-size: 3rem;
      line-height: 30px;
      line-height: 3.75rem; } }

.process-list-item__description {
  position: relative;
  flex: 1;
  z-index: 1;
  line-height: 1.5rem;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px; }

.process-list-item__meta {
  display: flex;
  justify-content: space-between;
  color: #7aaedb; }

.process-list-item__link {
  text-transform: uppercase; }

.process-detail__item {
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0; }
  .process-detail__item.active {
    opacity: 1; }

.process-content {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media (min-width: 700px) {
    .process-content {
      height: 100vh; }
      .process-content.condensed {
        flex: 1;
        padding: 0 30px; }
      .process-content.collapsed {
        overflow: hidden;
        flex: 0;
        padding: 0; }
        .process-content.collapsed .page-title {
          opacity: 0; }
        .process-content.collapsed .page-description {
          opacity: 0; } }

.process-detail {
  position: relative;
  padding: calc(var(--vh, 1vh) * 50 - 37.5px) 0;
  margin-top: 75px;
  flex: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden; }
  @media (min-width: 700px) {
    .process-detail {
      margin-top: 0;
      padding: 50vh 0; } }
  .process-detail.expanded {
    flex: 1; }
  .process-detail .image-pod {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .process-detail .image-pod img {
      filter: grayscale(10);
      mix-blend-mode: multiply;
      width: 100%;
      object-fit: cover;
      object-position: center center; }

.process-detail__text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  padding: 0 40px;
  transform: translateX(-50%) translateY(-50%); }
  @media (min-width: 700px) {
    .process-detail__text {
      padding: 0 60px; } }

.process-detail__title {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 25px;
  line-height: 3.125rem;
  opacity: 0;
  font-family: 'NoyhBold', Arial, sans-serif;
  color: #fff; }
  @media (min-width: 700px) {
    .process-detail__title {
      font-size: 40px;
      font-size: 4rem;
      line-height: 40px;
      line-height: 5rem;
      line-height: 3.5rem;
      padding-bottom: 20px; } }

.process-detail__description {
  line-height: 1.5rem;
  opacity: 0;
  color: #fff;
  font-family: "Alegreya", Arial, sans-serif;
  letter-spacing: 0.5px; }
  @media (min-width: 700px) {
    .process-detail__description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem; } }

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.page-practice .background {
  transform: rotate(180deg); }

.work-slides {
  pointer-events: none;
  width: 100vw; }
  .work-slides.compact {
    pointer-events: all; }
  @media (min-width: 700px) {
    .work-slides {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      overflow: scroll; } }

.work-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.work-intro__container {
  display: flex;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  align-items: center;
  flex-direction: column; }
  .work-intro__container .page-info,
  .work-intro__container .logo-container {
    flex: 1; }
  @media (min-width: 700px) {
    .work-intro__container {
      flex-direction: row; }
      .work-intro__container .page-info {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        padding-top: 0;
        padding-bottom: 0;
        transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1); }
        .work-intro__container .page-info:after {
          content: '';
          width: 1px;
          height: 100%;
          background: #fff;
          position: absolute;
          right: 0;
          top: 0; } }

.logo-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 20px; }
  @media (min-width: 700px) {
    .logo-container {
      padding: 0 40px; } }

.client-logo {
  display: flex;
  width: 50%;
  height: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px;
  opacity: 0; }
  .client-logo img {
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 100px;
    display: inline-block; }

.page-practice__detail {
  padding-top: 0; }

.work-detail {
  position: relative;
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column; }

.work-detail__background {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%; }

.work-detail__column {
  flex: 1;
  display: none; }
  @media (min-width: 700px) {
    .work-detail__column {
      display: block; } }

.work-detail__column--right {
  position: relative;
  display: block; }

.work-detail__container {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column; }
  @media (min-width: 700px) {
    .work-detail__container {
      flex-direction: row; } }

.work-detail__content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 20px;
  width: 100%;
  flex: 1;
  align-items: center;
  overflow-x: hidden; }
  .work-detail__content--left {
    padding: 100px 20px 40px; }
  .work-detail__content .slick-dots {
    padding: 0 0 20px; }
  @media (min-width: 700px) {
    .work-detail__content {
      flex-direction: row;
      width: 50%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100); } }

.work-detail__content-column {
  flex: 1; }
  @media (min-width: 700px) {
    .work-detail__content-column {
      padding: 20px; } }

.work-detail__content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center; }

.work-detail__client {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 15px;
  line-height: 1.875rem;
  color: #fff;
  font-family: "Alegreya", Arial, sans-serif;
  padding-bottom: 10px; }

.work-detail__name {
  font-size: 28.75px;
  font-size: 2.875rem;
  line-height: 28.75px;
  line-height: 3.59375rem;
  line-height: 3rem;
  color: #fff;
  font-family: "Noyh", Arial, sans-serif;
  padding-bottom: 10px; }

.work-detail__description {
  font-size: 12.5px;
  font-size: 1.25rem;
  line-height: 12.5px;
  line-height: 1.5625rem;
  line-height: 2rem;
  font-family: "Alegreya", Arial, sans-serif;
  font-kerning: none;
  color: #fff; }

.work-detail__services {
  font-size: 11.25px;
  font-size: 1.125rem;
  line-height: 11.25px;
  line-height: 1.40625rem;
  padding-top: 20px;
  color: #fff;
  font-family: "Noyh", Arial, sans-serif; }

.work-detail__services-title {
  font-size: 17.5px;
  font-size: 1.75rem;
  line-height: 17.5px;
  line-height: 2.1875rem;
  opacity: 0;
  transform: translateY(20px);
  color: #fff;
  font-family: "Noyh", Arial, sans-serif;
  padding-bottom: 10px; }

.work-detail__services-list {
  opacity: 0;
  transform: translateY(20px); }

.work-detail__service {
  font-size: 13.75px;
  font-size: 1.375rem;
  line-height: 13.75px;
  line-height: 1.71875rem;
  color: #fff;
  font-family: "Noyh", Arial, sans-serif;
  padding: 5px 0; }

.work-detail__thumbs {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  overflow: scroll; }

.work-detail__thumb {
  cursor: pointer;
  min-width: 33.333%;
  flex: 1;
  height: 50px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer; }
  .work-detail__thumb.active {
    height: 75px;
    pointer-events: none; }
  @media (min-width: 700px) {
    .work-detail__thumb {
      min-width: 12.5%; } }
  .work-detail__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .work-detail__thumb:hover {
    opacity: 1;
    height: 75px; }

#block_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .loaded #block_left {
    opacity: 1; }

.page-words {
  display: flex;
  padding: 0; }
  @media (min-width: 700px) {
    .page-words {
      overflow: hidden; } }

.words-layout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column; }
  @media (min-width: 700px) {
    .words-layout {
      flex-direction: row;
      height: 100vh;
      overflow: hidden; } }

.words-container {
  position: relative;
  flex: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgba(255, 255, 255, 0.8); }
  .words-container.expanded {
    flex: 1; }
  .words-container #sideBlock {
    opacity: 0.8; }
  @media (min-width: 700px) {
    .words-container {
      overflow: hidden;
      background: transparent; } }

.word-list {
  position: relative;
  z-index: 1; }
  @media (min-width: 700px) {
    .word-list {
      padding-top: 60px;
      height: 100vh;
      overflow: scroll; } }

.word-list__back {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 20;
  cursor: pointer; }
  @media (min-width: 700px) {
    .word-list__back {
      display: block; } }

.word-list-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 200px;
  padding: 20px;
  overflow: hidden; }
  .word-list-item:hover .word-list-item__background, .word-list-item.active .word-list-item__background {
    opacity: 1; }
  @media (min-width: 700px) {
    .word-list-item {
      opacity: 0; } }

.word-list-item__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #a0d8de;
  padding: 2px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.word-list-item__image {
  position: relative;
  z-index: 1; }
  .word-list-item__image img {
    height: 100%;
    display: block; }

.word-list-item__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'NoyhBook', Arial, sans-serif;
  padding-left: 20px; }

.word-list-item__title {
  font-size: 12.5px;
  font-size: 1.25rem;
  line-height: 12.5px;
  line-height: 1.5625rem;
  line-height: 1.5rem;
  font-family: "Noyh", Arial, sans-serif;
  position: relative;
  z-index: 1;
  padding-bottom: 10px; }
  @media (min-width: 700px) {
    .word-list-item__title {
      font-size: 20px;
      font-size: 2rem;
      line-height: 20px;
      line-height: 2.5rem;
      line-height: 2rem; } }

.word-list-item__description {
  line-height: 1.25rem;
  font-family: "Alegreya", Arial, sans-serif;
  position: relative;
  flex: 1;
  z-index: 1;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px; }
  @media (min-width: 700px) {
    .word-list-item__description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem; } }

.word-list-item__meta {
  display: flex;
  justify-content: space-between;
  color: #7aaedb; }

.word-list-item__link {
  text-transform: uppercase; }

.words-content {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media (min-width: 700px) {
    .words-content {
      height: 100vh; }
      .words-content.condensed {
        flex: 1; }
      .words-content.collapsed {
        overflow: hidden;
        flex: 0; }
        .words-content.collapsed .page-title {
          opacity: 0; }
        .words-content.collapsed .page-description {
          opacity: 0; } }

.words-detail {
  position: relative;
  background: #fff;
  padding: 0;
  flex: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: scroll; }
  .words-detail.expanded {
    flex: 1; }
  .words-detail .image-pod {
    height: 200px;
    overflow: hidden; }
    .words-detail .image-pod img {
      width: 100%;
      object-fit: cover;
      object-position: center center; }

.words-detail__social {
  display: flex;
  padding: 20px 0 0;
  flex-direction: column; }

.words-detail__social-icons {
  display: flex; }

.SocialMediaShareButton {
  margin: 0 2.5px;
  outline: none;
  cursor: pointer; }
  .SocialMediaShareButton:first-child {
    margin-left: 0; }

.words-detail__content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 100%; }

.words-detail__image {
  height: 200px; }

.words-detail__meta {
  line-height: 1.5rem;
  font-family: 'NoyhBook', Arial, sans-serif;
  padding: 30px 20px;
  color: #444;
  opacity: 0;
  padding-bottom: 10px; }
  @media (min-width: 700px) {
    .words-detail__meta {
      padding: 50px 20px 0; } }

.words-detail__date {
  color: #7aaedb;
  font-weight: 700;
  line-height: 0; }

.words-detail__posted-by {
  color: #444;
  font-weight: 700;
  line-height: 0; }

.words-detail__title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 20px;
  line-height: 2.5rem;
  line-height: 2.5rem;
  font-family: "Noyh", Arial, sans-serif;
  padding: 10px 0; }
  @media (min-width: 700px) {
    .words-detail__title {
      font-size: 30px;
      font-size: 3rem;
      line-height: 30px;
      line-height: 3.75rem; } }

.words-detail__description {
  line-height: 2rem;
  flex: 1;
  overflow: scroll;
  font-family: "Alegreya", Arial, sans-serif;
  letter-spacing: 0.5px;
  padding: 20px;
  margin-top: 10px;
  opacity: 0;
  font-kerning: none; }
  .words-detail__description p {
    padding-bottom: 20px; }
  @media (min-width: 700px) {
    .words-detail__description {
      font-size: 12.5px;
      font-size: 1.25rem;
      line-height: 12.5px;
      line-height: 1.5625rem;
      line-height: 2rem; } }

.words-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  order: 1;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  order: 2; }
  .words-image .image-pod {
    flex: 1; }
  .words-image.image-loaded {
    transform: translateX(0%); }

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
