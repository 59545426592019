.arrow {
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  height: 30px;
  width: 15px;
  transform: translate(-50%);
  transition: all 0.3s ease-out 2s;

  .loaded & {
    opacity: 1;
  }
}
.arrow span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid $white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-8px, -8px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(8px, 8px);
  }
}
