html {
  font-size: 100%;
}

html,
body {
  width: 100vw;
  background: $color-blue--light;

  @media #{$tablet} {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

#root {
  @media #{$tablet} {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  }
}

a {
  color: $white;
}

.main-content {
  @media screen and (max-width: 700px) {
    top: 75px;
    position: fixed;
    overflow: scroll;
    height: calc(var(--vh, 1vh) * 100 - 75px);
    -webkit-overflow-scrolling: touch;
  }

  &.home {
    top: 0;
    height: 100vh;
  }
}
