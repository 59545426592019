$white: #fff;

$color-pink: #de8990;
$color-pink--light: #f2adb2;
$color-pink--dark: #ce1974;
$color-pink--bright: #ff33ad;
$color-blue: #7aaedb;
$color-blue--home: #29abe2;
$color-blue--light: #9cd5e7;
$color-blue--dark: #248dc1;
$color-green: #7ecaab;
$color-green--dark: #108754;
$color-yellow: #efd796;
$color-red: #181313;
$color-red--dark: #ac504e;
$color-purple: #60288c;
$color-text: #444;
