.page-practice__detail {
  padding-top: 0;
  // background: $white;
}

.work-detail {
  position: relative;
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.work-detail__background {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}

.work-detail__column {
  flex: 1;
  display: none;

  @media #{$tablet} {
    display: block;
  }
}

.work-detail__column--right {
  position: relative;
  display: block;
}

.work-detail__container {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.work-detail__content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: $default-spacing * 2;
  width: 100%;
  flex: 1;
  align-items: center;
  overflow-x: hidden;

  &--left {
    padding: $default-spacing * 10 $default-spacing * 2 $default-spacing * 4;
  }

  .slick-dots {
    padding: 0 0 $default-spacing * 2;
  }

  @media #{$tablet} {
    flex-direction: row;
    width: 50%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.work-detail__content-column {
  flex: 1;
  @media #{$tablet} {
    padding: $default-spacing * 2;
  }
}

.work-detail__content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.work-detail__client {
  @include font-size(1.5);
  color: $white;
  font-family: $font-secondary;
  padding-bottom: $default-spacing;
}

.work-detail__name {
  @include font-size(2.875);
  line-height: 3rem;
  color: $white;
  font-family: $font-primary;
  padding-bottom: $default-spacing;
}

.work-detail__description {
  @include font-size(1.25);
  line-height: 2rem;
  font-family: $font-secondary;
  font-kerning: none;
  color: $white;
}

.work-detail__services {
  @include font-size(1.125);
  padding-top: $default-spacing * 2;
  color: $white;
  font-family: $font-primary;
}

.work-detail__services-title {
  @include font-size(1.75);
  opacity: 0;
  transform: translateY(20px);
  color: $white;
  font-family: $font-primary;
  padding-bottom: $default-spacing;
}

.work-detail__services-list {
  opacity: 0;
  transform: translateY(20px);
}

.work-detail__service {
  @include font-size(1.375);
  color: $white;
  font-family: $font-primary;
  padding: $default-spacing * 0.5 0;
}

.work-detail__thumbs {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;

  z-index: 1;
  overflow: scroll;
}

.work-detail__thumb {
  cursor: pointer;
  min-width: 33.333%;
  flex: 1;
  height: 50px;
  opacity: 0;
  transition: all 0.3s $ease-out-cubic;
  cursor: pointer;

  &.active {
    height: 75px;
    pointer-events: none;
  }

  @media #{$tablet} {
    min-width: 12.5%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    opacity: 1;
    height: 75px;
  }
}

#block_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.8s $ease-out-cubic;

  .loaded & {
    opacity: 1;
  }
}
