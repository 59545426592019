.page-about {
  display: flex;
  padding: 0;
  // background: #18cfbf;

  @media #{$tablet} {
    overflow: hidden;
  }
}

.about-layout {
  display: flex;
  position: relative;

  width: 100%;
  flex-direction: column;
  min-height: 100vh;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.about-content {
  position: relative;
  flex: 3;
  order: 2;
  display: flex;
  align-items: center;

  @media #{$tablet} {
    height: 100vh;
  }
}

.about-image {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  order: 1;
  transition: all 0.5s $ease-out-back;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  order: 2;

  .image-pod {
    flex: 1;
  }

  &.image-loaded {
    transform: translateX(0%);
  }

  @media #{$tablet} {
    flex: 2;
  }
}

#sideBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
