.contact-info {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-top: $default-spacing * 5;

  a,
  a:visited {
    color: $white;
    text-decoration: none;
  }

  .greater-navigation & {
    margin-top: auto;
  }

  #bottomBump {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-height: 500px) {
    width: 100%;
    height: auto;
  }
}

.contact-info__container {
  position: relative;
  z-index: 1;
  display: flex;
  padding: $default-spacing * 3 $default-spacing $default-spacing;
  width: 100%;
  height: 100%;
  justify-content: space-around;

  @media #{$tablet} and (min-height: 500px) {
    flex-direction: row;
  }
}

.contact-info__column {
  @include font-size(0.8625);
  display: flex;
  font-family: 'NoyhLight', Arial, sans-serif;
  color: $white;
  padding: 0 $default-spacing;

  @media #{$tablet} {
    @include font-size(1.25);
    padding: 0 $default-spacing $default-spacing;
  }
}

.contact-info__social {
  display: flex;
  padding-top: $default-spacing;
}

.contact-info__tagline {
  color: rgba($white, 0.5);
  font-family: 'NoyhLightItalic', Arial, sans-serif;
  padding: $default-spacing 0;
}

.contact-info__column--social {
  flex-direction: column;
}

.contact-info__label {
  font-family: 'NoyhLightItalic', Arial, sans-serif;
  color: $white;
  display: none;

  @media #{$tablet} and (min-height: 600px) {
    display: inline;
  }
}

.contact-info__divider {
  margin: 8px $default-spacing * 2;
  flex: 1;
  min-width: 30px;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  display: none;

  @media #{$tablet} and (min-height: 500px) {
    display: inline;
  }
}

.contact-info__content {
  color: $white;
}

a.email-link {
  color: $color-green--dark;
}

.social-link {
  max-width: 20px;
  margin-right: $default-spacing;

  img {
    width: 100%;
  }
}
