.work-item {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 0;
  margin: 0;
  // opacity: 0;

  &:hover {
    .work-item__content {
      opacity: 1;
    }

    .work-item__client {
      opacity: 1;
      transform: translateY(0);
    }

    .work-item__project {
      opacity: 1;
      transform: translateY(0);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 600px) {
    .work-item__content {
      opacity: 1;
    }

    .work-item__client {
      opacity: 1;
      transform: translateY(0);
    }

    .work-item__project {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media #{$tablet} {
    width: 50%;
  }

  @media screen and (min-width: 750px) {
    width: 33.333%;
  }

  // @media screen and (min-width: 900px) {
  //   width: 25%;
  // }
}

.work-item__background {
  width: 100%;
  height: 100%;
}

.work-item__content {
  position: absolute;
  color: $white;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba($color-pink--bright, 0) 50%,
    rgba($color-pink--bright, 0.8)
  );
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s $ease-out-cubic;
  padding-left: $default-spacing * 2;

  @media #{$tablet} {
    background: rgba($color-pink--bright, 0.8);
    padding-left: $default-spacing * 4;
  }
}

.work-item__client {
  @include font-size(1.5);
  font-family: $font-primary;
  display: flex;
  align-items: center;
  padding-bottom: $default-spacing;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s $ease-out-cubic;
}

.work-item__project {
  @include font-size(2);
  font-family: $font-secondary;
  padding-bottom: $default-spacing * 3;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s $ease-out-cubic 0.1s;
}
