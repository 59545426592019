.greater-navigation {
  position: relative;
  font-family: 'NoyhBold', Arial, sans-serif;
  z-index: 12;
  pointer-events: none;

  .logo svg {
    path,
    #period circle {
      fill: $color-pink;
    }
    circle,
    line {
      stroke: $color-pink;
    }

    #period circle {
      stroke: transparent;
    }
  }

  &.alignment .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.about .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.work .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.process .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.battle-tested .logo svg {
    path,
    #period circle {
      fill: $color-blue--dark;
    }
    circle,
    line {
      stroke: $color-blue--dark;
    }
  }

  &.contact .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.writing .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.writing-detail .logo svg {
    path,
    #period circle {
      fill: $color-pink;
    }
    circle,
    line {
      stroke: $color-pink;
    }
  }

  &.open .logo svg {
    path,
    #period circle {
      fill: $white;
    }
    circle,
    line {
      stroke: $white;
    }
  }

  &.about,
  &.contact,
  &.writing,
  &.battle-tested,
  &.process,
  &.work,
  &.alignment {
    .hamburger {
      width: 30px;
    }
    .hamburger-helper {
      display: none;
    }
  }

  .contact-info__container {
    opacity: 0;
    transform: translateY(30px);
    pointer-events: auto;
  }
}

.navigation-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  #backEnd,
  #frontEnd {
    visibility: hidden;
  }
}

.logo {
  cursor: pointer;
  width: 150px;
  transition: all 1s $ease-out-back;
  pointer-events: auto;

  svg {
    width: 100%;
  }
}

.greater-navigation__header {
  top: 0;
  position: fixed;
  width: 100%;
  padding: $default-spacing * 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.hamburger {
  width: 140px;
  height: 30px;
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  align-items: center;
  transition: all 0.3s $ease-in-out-cubic;
  opacity: 0;
  transform: translateY(10px);

  &.load-anim {
    transform: translateY(0px);
    opacity: 1;
  }
}

.hamburger-lines {
  width: 20px;
  height: 20px;
  justify-self: flex-end;
}

.hamburger-helper {
  font-size: 1rem;
  position: relative;
  flex: 1;
  text-align: right;
  text-transform: uppercase;
  color: $white;
  padding-right: $default-spacing * 3;
  transition: all 0.5s $ease-in-out-cubic;

  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjQsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yNC4yOTEsMTQuMjc2TDE0LjcwNSw0LjY5Yy0wLjg3OC0wLjg3OC0yLjMxNy0wLjg3OC0zLjE5NSwwbC0wLjgsMC44Yy0wLjg3OCwwLjg3Ny0wLjg3OCwyLjMxNiwwLDMuMTk0DQoJTDE4LjAyMywxNmwtNy4zMTQsNy4zMTRjLTAuODc4LDAuODc5LTAuODc4LDIuMzE3LDAsMy4xOTRsMC44LDAuOGMwLjg3OCwwLjg3OSwyLjMxNywwLjg3OSwzLjE5NSwwbDkuNTg2LTkuNTg2DQoJYzAuNDcyLTAuNDcyLDAuNjgzLTEuMTA0LDAuNjQ3LTEuNzI0QzI0Ljk3MywxNS4zOCwyNC43NjQsMTQuNzQ4LDI0LjI5MSwxNC4yNzZ6Ii8+DQo8L3N2Zz4NCg==')
      no-repeat scroll 0 0 transparent;
    background-size: contain;
    animation: loopArrow 2s $ease-in-out-cubic infinite;
  }
}

.hamburger-line {
  width: 20px;
  height: 2px;
  background: $white;
  margin-bottom: 7px;
  border-radius: 5px;
  transition: all 0.2s $ease-out-cubic;

  &:last-child {
    margin: 0;
  }

  .writing & {
    @media #{$tablet} {
      background: #00ce9b;
    }
  }

  .open & {
    background: $white;
  }
}

.open {
  .burger-top {
    width: 70%;
    transform: rotate(-45deg) translateY(2px) translateX(-4px);
  }

  .burger-bottom {
    width: 70%;
    transform: rotate(45deg) translateY(-2px) translateX(-4px);
  }

  .hamburger-helper {
    transform: translateY(10px);
    opacity: 0;
  }
}

.greater-navigation__content {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  transition: all 0.3s $ease-in-out-quint;
  z-index: 2;
  align-items: center;
  flex-direction: row;

  @media screen and (min-height: 500px) {
    flex-direction: column;
  }
}

.greater-navigation__content-list {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  max-height: 450px;
  padding: 75px 0 40px;
  width: 50%;
  z-index: 1;

  @media screen and (min-height: 500px) {
    width: 100%;
  }

  @media #{$tablet} {
    max-height: none;
  }
}

.greater-navigation__content-list__item {
  @include font-size(1.5);
  pointer-events: auto;
  position: relative;
  color: $white;
  letter-spacing: 2px;
  cursor: pointer;
  transform: translateY(10px);
  opacity: 0;

  a,
  a:visited {
    text-decoration: none;
    color: #216591;
    transition: all 0.3s ease-out;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 110%;
    width: 20px;
    height: 3px;
    border-radius: 3px;
    background: $white;
    opacity: 0;
    transition: all 0.3s $ease-out-cubic;
  }
  &:hover {
    a {
      color: $white;
    }

    &:after,
    &:before {
      background: $white;
      transform: translateY(-4.5px) translateX(20px) rotate(30deg);
      width: 20px;
      opacity: 1;
    }

    &:before {
      transform: translateY(4.5px) translateX(20px) rotate(-30deg);
    }
  }

  @media screen and (min-width: 500px) {
    @include font-size(2);
  }
}

@keyframes loopArrow {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  50% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}
