.slick-dots {
  bottom: 0;
  li button:before {
    color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    opacity: 1;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 10px;
  }

  .slick-active button:before {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid transparent;
  }
}
