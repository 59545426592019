.custom-dots {
  opacity: 0;
  transform: translateY(30px);
  margin: auto;
  position: relative;
  text-align: center;
  margin-top: $default-spacing * 6;
  padding-top: $default-spacing * 6;
  transition: all 0.3s $ease-out-cubic 0.2s;

  .loaded & {
    opacity: 1;
    transform: translateY(0);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15%;
    width: 70%;
    height: 1px;
    background: rgba($white, 0.5);
  }
}

.dot {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: $default-spacing * 0.5;
  margin: 0 $default-spacing * 0.5;
  width: 20px;
  height: 20px;
  flex: 1;
  transition: all 0.2s $ease-out-cubic;

  &:before {
    content: '';
    display: block;
    position: relative;
    width: 10px;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: transparent;
    border-radius: 10px;
    transition: all 0.2s $ease-out-cubic;
  }

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    border: 1px solid $white;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    opacity: 0;
    transition: all 0.2s $ease-out-cubic;
  }

  &.active:before {
    background: rgba(0, 0, 0, 0.5);
    border-color: transparent;
  }

  &:hover {
    &:before {
      opacity: 0;
    }
    &:after {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      opacity: 1;
    }
    .dot-title {
      top: 25px;
      opacity: 1;
    }
  }
}

.dot-title {
  position: absolute;
  display: inline-block;
  pointer-events: none;
  text-transform: uppercase;
  padding-top: $default-spacing;
  color: $white;
  font-family: $font-primary;
  top: 30px;
  left: 10px;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.5s $ease-out-cubic;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -10px;
    height: 15px;
    width: 1px;
    background: $white;
  }
}
