.contact-slide {
  position: absolute;
  padding: 0 $default-spacing * 2;
  width: 100vw;
  max-width: 800px;
  height: calc(var(--vh, 1vh) * 100 - 150px);

  top: 0;
  display: flex;
  color: $white;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  transform: translateY(20px);

  @media #{$tablet} {
    height: calc(100vh - 75px);
  }

  .contact-page__subtitle {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic;
  }

  .contact-page__inputs {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic 0.2s;
  }

  .contact-page__title {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic 0.1s;
  }

  .contact-page__options {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic 0.2s;
  }

  .contact-nav {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic;
  }

  .contact-submit {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s $ease-in-out-cubic;
  }

  &.active {
    pointer-events: all;

    .contact-nav,
    .contact-page__inputs,
    .contact-page__subtitle,
    .contact-page__title,
    .contact-page__options,
    .contact-submit {
      opacity: 1;
      transform: translateY(0);

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

.contact-page__subtitle {
  @include font-size(1);
  font-family: $font-secondary;
  letter-spacing: 1px;
  opacity: 0;
  padding-bottom: $default-spacing;
  transform: translateY(-$default-spacing);
  transition: all 0.3s $ease-in-out-sine 0.15s;

  @media #{$tablet} {
    @include font-size(1.5);
  }
}

.contact-nav,
.contact-submit {
  @include font-size(1.5);
  text-transform: uppercase;
  font-family: $font-primary;
  cursor: pointer;
  color: $white;
  padding: $default-spacing * 2 0;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: none;
  transition: all 0.3s $ease-in-out-sine;

  svg {
    margin: 0 $default-spacing;
  }
}

.contact-nav svg {
  margin-left: 0;
}

.contact-page__title {
  @include font-size(2);
  line-height: 2rem;
  font-family: 'NoyhBold', Arial, sans-serif;

  @media #{$tablet} {
    @include font-size(4);
  }
}

.contact-page__inputs {
  display: flex;
  flex-direction: column;

  input,
  button,
  textarea {
    @include font-size(1.25);
    outline: none;
    resize: none;
    -webkit-appearance: none;
    font-family: $font-primary;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba($white, 0.6);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba($white, 0.6);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba($white, 0.6);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba($white, 0.6);
    }
  }

  [type='text'],
  textarea {
    background: transparent;
    color: $white;
    width: 100%;
    border: 2px solid $white;
    padding: $default-spacing $default-spacing * 2;
    margin: $default-spacing 0;
    border-radius: 20px;
  }

  [type='submit'] {
    @include font-size(1.5);
    background: transparent;
    color: $white;
    font-family: $font-primary;
    border: 0;
    text-align: left;
  }
}

.contact-page__options {
  display: flex;
  flex-direction: column;
  padding-top: $default-spacing;

  @media #{$tablet} {
    flex-direction: row;
  }
}

.contact-page__option {
  @include font-size(1.5);
  width: 100%;
  cursor: pointer;
  font-family: $font-primary;
  background: transparent;
  color: $white;
  padding: $default-spacing $default-spacing * 2;
  margin: $default-spacing $default-spacing * 2 0 0;
  border: 2px solid $white;
  border-radius: 100px;
  text-align: center;
  transition: all 0.3s $ease-out-cubic;
  outline: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.selected {
    background: $white;
    color: $color-blue--light;
  }
}
