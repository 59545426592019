.alignment-quote {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: all 1s $ease-in-out-cubic;
  z-index: 9;

  &.active {
    opacity: 1;
    z-index: 10;
  }

  .image-pod {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    filter: grayscale(10);
    mix-blend-mode: multiply;
  }
}

.alignment-quote__content {
  color: $white;
  position: relative;
  z-index: 2;
  padding: $default-spacing * 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alignment-quote__text {
  @include font-size(1.25);
  line-height: 1.5rem;
  font-family: $font-secondary;
  padding: $default-spacing * 2 0;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 1s $ease-in-out-cubic 0.75s;

  .active & {
    transform: translateY(0);
    opacity: 1;
  }

  @media #{$tablet} {
    @include font-size(2);
  }
}

.alignment-quote__attribution {
  text-transform: uppercase;
  font-family: $font-primary;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 1s $ease-in-out-cubic 0.5s;

  .active & {
    transform: translateY(0);
    opacity: 1;
  }
}
